import React from 'react'
import {useState} from 'react'
import Contact from '../components/Contact'
import A from '../components/Contact/a'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'


const Kontakt = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>
            <Contact />
            <A />
            <Footer />
        </>
    )
}

export default Kontakt
