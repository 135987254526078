import React, { useEffect, useState } from 'react'
import logo from '../../images/logo.png'
import { FaBars } from 'react-icons/fa'
import {Nav, NavContainer, NavLogoWrapper, MobileIcon, NavMenu, NavItem, NavLinks, NavLogo, NavCom, NavLogoText} from './NavbarE'

const Navbar = ({toggle}) => {
    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if(window.scrollY >= 90) {
            setScrollNav(true)
        } else { 
            setScrollNav(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])

    return (
        <>
            <NavCom >
                <Nav scrollNav={scrollNav}>
                    <NavContainer>
                        <NavLogoWrapper to='/' target="_top">
                            <NavLogo src={logo} scrollNav={scrollNav}/>
                            <NavLogoText scrollNav={scrollNav}>KomuCiacho</NavLogoText>
                        </NavLogoWrapper>
                        <MobileIcon onClick={toggle}>
                            <FaBars />
                        </MobileIcon>
                        <NavMenu>
                            <NavItem>
                                <NavLinks to="/aktualnosci" target="_top">Aktualności</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to="/oferta" target="_top">Oferty</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to="/galeria" target="_top">Galeria</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to="/kontakt" target="_top">Kontakt</NavLinks>
                            </NavItem>
                        </NavMenu>
                    </NavContainer>
                </Nav>
            </NavCom>
        </>
    )
}

export default Navbar
