import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink} from './SidebarE'

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to='/aktualnosci' target="_top">Aktualności</SidebarLink>
                    <SidebarLink to='/oferta' target="_top">Oferty</SidebarLink>
                    <SidebarLink to='/galeria' target="_top">Galeria</SidebarLink>
                    <SidebarLink to='/kontakt' target="_top">Kontakt</SidebarLink>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
