import React, {useState} from 'react'
import { SliderData } from './SliderData'
import './style.css'
import {
    Image,
    Slider,
    ArrowLeft,
    ArrowRight,
    SliderTitle,
    SliderH1,
    SliderText,
    SliderWrapper
} from './ImageSliderE'

const Imageslider = ( {items} ) => {
    const [current, setCurrent] = useState(0);
    const length = items.length;

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1)
    }

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1)
    }

    if(!Array.isArray(items) || items.length <= 0) {
        return null;
    }

    return (
        <>
            <Slider>
                <SliderTitle>
                    <SliderH1>Witrynka</SliderH1>
                    <SliderText>Zapraszamy do zakupu ciast i słodkości stacjonarnie w naszej pracowni.</SliderText>
                </SliderTitle>
                <ArrowLeft onClick={prevSlide}/>
                <ArrowRight onClick={nextSlide}/>
                {SliderData.map((item, index) => {
                    return (
                        <div className={index === current ? 'slide active' : 'slide'}
                        key={index}>
                        {index === current && (
                            <SliderWrapper>
                                <SliderText>{item.text}</SliderText>
                                <Image src={item.image} alt='carusel' />
                            </SliderWrapper>
                            )}
                        </div>
                    )
                })}
            </Slider>
        </>
    )
}

export default Imageslider
