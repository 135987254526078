import g1 from '../../images/godz.jpg'
import g2 from '../../images/witrynka1.jpg'
import g3 from '../../images/witrynka2.jpg'
import g4 from '../../images/witrynka3.jpg'
import g5 from '../../images/witrynka4.jpg'

export const SliderData = [
    {
        image: g1,
        text: ''
    },
    {
        image: g2,
        text: ''
    },
    {
        image: g3,
        text: ''
    },
    {
        image: g4,
        text: ''
    },
    {
        image: g5,
        text: ''
    }
]