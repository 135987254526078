import React, { useState } from 'react'
import g1 from '../../images/ga1.jpg'
import g2 from '../../images/ga2.jpg'
import g3 from '../../images/ga5.jpg'
import g4 from '../../images/ga4.jpg'
import { GalleryContainer, Gc, GalleryH1, GalleryWrap, ImgWrap, GalleryImg, GalleryLink, ArrowForward, ArrowRight} from './GalleryE'

const Gallery = () => {
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover)
    }
    return (
        <GalleryContainer>
            <Gc>
                <GalleryH1>Galeria</GalleryH1>
                <hr style={{width: '65%', margin: '2px'}} /><hr style={{width: '25%', margin: '2px'}} /><hr style={{width: '45%', margin: '2px'}} />
                <GalleryWrap>
                    <ImgWrap>
                        <GalleryImg src={g1}/>
                    </ImgWrap>
                    <ImgWrap>
                        <GalleryImg src={g2}/>
                    </ImgWrap>
                    <ImgWrap>
                        <GalleryImg src={g3}/>
                    </ImgWrap>
                    <ImgWrap>
                        <GalleryImg src={g4}/>
                    </ImgWrap>
                </GalleryWrap>
                <GalleryLink to='/galeria' onMouseEnter={onHover} onMouseLeave={onHover} target="_top">Sprawdź naszą galerię {hover ? <ArrowForward /> : <ArrowRight />}</GalleryLink>
            </Gc>
        </GalleryContainer>
    )
}

export default Gallery
