import React from 'react'
import of1 from '../../images/of1.jpg'
import oferty_ciasta from '../../images/oferty_ciasta.jpg'
import slodkosci from '../../images/slodkosci.jpg'
import oferta_weselna from '../../images/oferta_weselna.jpg'
import { AktsContainer, AktsWrapper, AktsImg, GridContainer, AktsH1, AktsH2, AktsContent, AktsP } from '../AktS/AktsE'

const Ofe = ( {setSelectedImg} ) => {

    return (
        <AktsContainer>
            <AktsH1>Oferty</AktsH1>
            <hr style={{width: '65%', margin: '2px'}} /><hr style={{width: '25%', margin: '2px'}} /><hr style={{width: '65%', margin: '2px'}} />
            <GridContainer>
                <AktsWrapper>
                    <AktsImg src={of1} onClick={() => setSelectedImg(of1)}/>
                    <AktsContent>
                        <AktsH2>Torty okolicznościowe</AktsH2>
                        <AktsP>Każdy tort tworzymy na indywidualne zamówienie. Jeśli szukasz czegoś wyjątkowego i niepowtarzalnego, to dobrze trafiłeś. Ulubiona bajka, hobby czy motyw kwiatowy? Zaskoczymy Cię nie tylko smakiem, ale też dekoracją. </AktsP>
                    </AktsContent>
                </AktsWrapper>
                <hr style={{width: '65%', margin: '2px'}} /><hr style={{width: '25%', margin: '2px'}} /><hr style={{width: '65%', margin: '2px'}} />
                <AktsWrapper>
                    <AktsContent>
                        <AktsH2>Słodkości</AktsH2>
                        <AktsP>Planujesz domową uroczystość, pragniesz uświetnić swoje przyjęcie mini candy barem? W naszej ofercie znajdziecie m.in. makaroniki, muffiny, tartaletki, monoporcje, które zaskoczą Twoich gości smakiem i ciekawym wyglądem.</AktsP>
                    </AktsContent>
                    <AktsImg src={slodkosci} onClick={() => setSelectedImg(slodkosci)}/>
                </AktsWrapper>
                <hr style={{width: '65%', margin: '2px'}} /><hr style={{width: '25%', margin: '2px'}} /><hr style={{width: '65%', margin: '2px'}} />
                <AktsWrapper>
                    <AktsImg src={oferty_ciasta} onClick={() => setSelectedImg(oferty_ciasta)}/>
                    <AktsContent>
                        <AktsH2>Ciasta</AktsH2>
                        <AktsP>W naszej pracowni uwielbiamy tworzyć nowoczesne wersje tradycyjnych ciast. W ofercie znajdują się takie pyszności jak sernik chałwowy, o smaku mango, czy czekoladowe brownie w kilku odsłonach smakowych. Żeby poznać całą ofertę ciast zapraszamy do kontaktu!</AktsP>
                    </AktsContent>
                </AktsWrapper>
                <hr style={{width: '65%', margin: '2px'}} /><hr style={{width: '25%', margin: '2px'}} /><hr style={{width: '65%', margin: '2px'}} />
                <AktsWrapper>
                    <AktsContent>
                        <AktsH2>Oferta weselna</AktsH2>
                        <AktsP>Szukacie wyjątkowych słodkości na swoje  wesele? Marzycie o pięknym, eleganckim, specjalnie dla Was zaprojektowanym torcie? Pragniecie zaskoczyć Waszych gości wyjątkowym, dopasowanym do motywu przewodniego wesela słodkim stołem? Zapraszamy do kontaktu!  Zaprojektujemy dla Was weselną słodką oprawę.</AktsP>
                    </AktsContent>
                    <AktsImg src={oferta_weselna} onClick={() => setSelectedImg(oferta_weselna)}/>
                </AktsWrapper>
            </GridContainer>
        </AktsContainer>
    )
}

export default Ofe
