import React from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import {useState} from 'react'
import Akts from '../components/AktS'
import Footer from '../components/Footer'
import Modal from '../components/AktS/Modal'

const Akt = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedImg, setSelectedImg] = useState(null);

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>
            <Akts setSelectedImg={setSelectedImg} />
            { selectedImg && <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg}/> }
            <Footer />
        </>
    )
}

export default Akt
