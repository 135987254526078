import React from 'react'
import { Container, Content, Mapa } from './aElement'

const A = () => {
    return (
        <>
            <Container>
                <Content>
                    <Mapa src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2487.697720170215!2d17.916690315766996!3d51.42697917962193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471aa54b521bc9a1%3A0x2d29e664bec25a62!2sKomuCiacho!5e0!3m2!1spl!2spl!4v1609120104234!5m2!1spl!2spl"></Mapa>
                </Content>
            </Container>
        </>
    )
}

export default A
