import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const OfferContainer = styled.div`
    background: rgba(250,250,250,0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`

export const OfferH1 = styled.h1`
    font-size: 2.7rem;
    margin: 30px auto;

    @media screen and (max-width: 1100px) {
        font-size: 2.2rem;
        margin: 25px auto;
    }

    @media screen and (max-width: 700px) {
        font-size: 2rem;
        margin: 20px auto;
    }

    @media screen and (max-width: 480px) {
        font-size: 1.8rem;
        margin: 15px auto;
    }
`

export const OfferRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1100px;
    margin: 30px auto 50px auto;

    @media screen and (max-width: 770px) {
        flex-direction: column;
        margin: 20px auto 40px auto;
    }
`

export const OfferContent2 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justyfi-content: center;
    margin: auto 100px;

    @media screen and (max-width: 1100px) {
        margin: auto 50px;
    }
` 

export const OfferH2 = styled.h2`
    font-size: 1.8rem;
    padding: 1rem 0;

    @media screen and (max-width: 1100px) {
        font-size: 1.6rem;
        padding: 0.8rem 0;
    }

    @media screen and (max-width: 700px) {
        font-size: 1.5rem;
        padding: 0.7rem 0;
    }

    @media screen and (max-width: 480px) {
        font-size: 1.4rem;
        padding: 0.6rem 0;
    }
`

export const OfferImg = styled.img`
    height: 550px;
    width: 400px;
    margin: 20px;
    -o-object-fit: cover;
    object-fit: cover;
    box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
    border-radius: 5px;

    @media screen and (max-width: 1100px) {
        height: 500px;
        width: 350px;
        margin: 15px 50px 15px 15px;
    }

    @media screen and (max-width: 770px) {
        margin: 10px;
    }

    @media screen and (max-width: 700px) {
        height: 450px;
        width: 300px;
    }

    @media screen and (max-width: 480px) {
        height: 400px;
        width: 250px;
    }
`

export const OfferP = styled.p`
    color: gray;
    text-align: center;
    font-size: 1.4rem;
    margin: 10px 10px auto;

    @media screen and (max-width: 1100px) {
        font-size: 1.2rem;
    }

    @media screen and (max-width: 700px) {
        font-size: 1.1rem;
    }
`

export const OfferLink = styled(Link)`
    font-size: 1.4rem;
    padding-top: 2.5rem;
    padding-bottom: 1.5rem;
    color: gray;
    text-decoration: none;

    &:hover {
        color: black;
    }

    @media screen and (max-width: 1100px) {
        font-size: 1.2rem;
        padding-top: 2rem;
        padding-bottom: 1rem;
    }

    @media screen and (max-width: 700px) {
        font-size: 1.1rem;
        padding-top: 1.5rem;
        padding-bottom: 0.5rem;
    }
`