import React from 'react'
import g1 from '../../images/h1.jpg'
import g2 from '../../images/h2.jpg'
import g3 from '../../images/h3.jpg'
import g4 from '../../images/h4.jpg'
import g5 from '../../images/h5.jpg'
import g6 from '../../images/h6.jpg'
import g7 from '../../images/h7.jpg'
import g8 from '../../images/h8.jpg'
import g9 from '../../images/h9.jpg'
import g10 from '../../images/h10.jpg'
import g11 from '../../images/h11.jpg'
import g12 from '../../images/h12.jpg'
import g13 from '../../images/h13.jpg'
import g14 from '../../images/h14.jpg'
import g15 from '../../images/h15.jpg'
import g16 from '../../images/h16.jpg'
import g17 from '../../images/h17.jpg'
import g18 from '../../images/h18.jpg'
import g19 from '../../images/h19.jpg'
import g20 from '../../images/h20.jpg'
import g21 from '../../images/h21.jpg'
import g22 from '../../images/h22.jpg'
import g23 from '../../images/h23.jpg'
import g24 from '../../images/h24.jpg'
import g25 from '../../images/h25.jpg'
import g26 from '../../images/h26.jpg'
import g27 from '../../images/h27.jpg'
import { 
    GContainer, 
    GridContainer, 
    GH2, 
    GLink,
    Img1,
    Img2,
    Img3,
    Img4,
    Img5,
    Img6,
    Img7,
    Img8,
    Img9,
    Img10,
    Img11,
    Img12,
    Img13,
    Img14,
    Img15,
    Img16,
    Img17,
    Img18,
    Img19,
    Img20,
    Img21,
    Img22,
    Img23,
    Img24,
    Img25,
    Img26,
    Img27
} from './GaleE'

const Gale = ( {setSelectedImg} ) => {

    return (
        <GContainer>
            <GridContainer>
                <Img1 src={g1} onClick={() => setSelectedImg(g1)}/>
                <Img2 src={g2} onClick={() => setSelectedImg(g2)}/>
                <Img3 src={g3} onClick={() => setSelectedImg(g3)}/>
                <Img4 src={g4} onClick={() => setSelectedImg(g4)}/>
                <Img5 src={g5} onClick={() => setSelectedImg(g5)}/>
                <Img6 src={g6} onClick={() => setSelectedImg(g6)}/>
                <Img7 src={g7} onClick={() => setSelectedImg(g7)}/>
                <Img8 src={g8} onClick={() => setSelectedImg(g8)}/>
                <Img9 src={g9} onClick={() => setSelectedImg(g9)}/>
                <Img10 src={g10} onClick={() => setSelectedImg(g10)}/>
                <Img11 src={g11} onClick={() => setSelectedImg(g11)}/>
                <Img12 src={g12} onClick={() => setSelectedImg(g12)}/>
                <Img13 src={g13} onClick={() => setSelectedImg(g13)}/>
                <Img14 src={g14} onClick={() => setSelectedImg(g14)}/>
                <Img15 src={g15} onClick={() => setSelectedImg(g15)}/>
                <Img16 src={g16} onClick={() => setSelectedImg(g16)}/>
                <Img17 src={g17} onClick={() => setSelectedImg(g17)}/>
                <Img18 src={g18} onClick={() => setSelectedImg(g18)}/>
                <Img19 src={g19} onClick={() => setSelectedImg(g19)}/>
                <Img20 src={g20} onClick={() => setSelectedImg(g20)}/>
                <Img21 src={g21} onClick={() => setSelectedImg(g21)}/>
                <Img22 src={g22} onClick={() => setSelectedImg(g22)}/>
                <Img23 src={g23} onClick={() => setSelectedImg(g23)}/>
                <Img24 src={g24} onClick={() => setSelectedImg(g24)}/>
                <Img25 src={g25} onClick={() => setSelectedImg(g25)}/>
                <Img26 src={g26} onClick={() => setSelectedImg(g26)}/>
                <Img27 src={g27} onClick={() => setSelectedImg(g27)}/>
            </GridContainer>
            <GH2>Więcej zdjęć znajdziecie na naszym <GLink href='https://www.facebook.com/komuciacho/' target='_blank' arial-label='Facebook'>Facebooku</GLink> i <GLink href='https://www.instagram.com/komuciacho/' target='_blank' arial-label='Instagram'>Instagramie</GLink>.</GH2>
        </GContainer>
    )
}

export default Gale
