import React from 'react'
import { Container } from './FreeSpaceE'

const FreeSpace = () => {
    return (
        <>
            <Container />
        </>
    )
}

export default FreeSpace
