import React from 'react'
import {FaFacebook, FaInstagram} from 'react-icons/fa'
import { GrMail } from 'react-icons/gr'
import {FooterC, Wrap, Logo, WRights, SocialIcons, SocialIconLink} from './FooterE'

const Footer = () => {
    return (
        <FooterC>
            <Wrap>
                <Logo to='/'>KomuCiacho</Logo>
                <WRights>© {new Date().getFullYear()} All rights reserved.</WRights>
                <SocialIcons>
                    <SocialIconLink href='https://www.facebook.com/komuciacho/' target='_blank' arial-label='Facebook'>
                        <FaFacebook />
                    </SocialIconLink>
                    <SocialIconLink href='https://www.instagram.com/komuciacho/' target='_blank' arial-label='Instagram'>
                        <FaInstagram />
                    </SocialIconLink>
                    <SocialIconLink href='/kontakt' target='_blank' arial-label='Mail'>
                        <GrMail />
                    </SocialIconLink>
                </SocialIcons>
            </Wrap>
        </FooterC>
    )
}

export default Footer
