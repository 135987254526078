import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Home from './pages';
import aktualnosci from './pages/aktualnosci';
import oferta from './pages/oferta';
import galeria from './pages/galeria'
import kontakt from './pages/kontakt';

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/' component={Home} exact />
        <Route path='/aktualnosci' component={aktualnosci} />
        <Route path='/oferta' component={oferta} />
        <Route path='/galeria' component={galeria} />
        <Route path='/kontakt' component={kontakt} />
      </Switch>
    </Router>
  );
}

export default App;
