import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    padding-top: 50px;
    background: rgba(250,250,250,0.9);
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Content = styled.div`
    max-width: 1100px;
    padding-bottom: 50px;
`

export const Mapa = styled.iframe`
    width: 600px;
    height: 450px;
    frameborder: 0;
    border: 0; 
    allowfullscreen: ""; 
    aria-hidden: false;
    tabindex: 0;
    box-shadow: 3px 5px 7px rgba(0,0,0,0.5);

    @media screen and (max-width: 700px) {
        width: 420px;
        height: 315px;
    }

    @media screen and (max-width: 480px) {
        width: 360px;
        height: 270px;
    }
`