import styled from 'styled-components'
import { FaTimes } from 'react-icons/fa'
import { Link as LinkR} from 'react-router-dom'

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #e1e2e5;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`

export const CloseIcon = styled(FaTimes)`
    color: #6e93ae;

    &:hover {
        color: black;
        border: 1px black solid;
        border-radius: 5px;
    }
`

export const Icon = styled.div`
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`

export const SidebarWrapper = styled.div`
    margin: 0 auto;
`

export const SidebarMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 80px);
    text-align: center;

    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(6, 60px);
    }
`

export const SidebarLink = styled(LinkR)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.7rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    color: #6e93ae;
    cursor: pointer;

    &:hover {
        color: black;
        font-weight: bold;
        transition: 0.2s ease-in-out;
    }
`