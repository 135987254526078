import styled from 'styled-components'
import {FaArrowAltCircleRight, FaArrowAltCircleLeft} from 'react-icons/fa'

export const Slider = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: rgba(250,250,250,0.9);
`

export const ArrowLeft = styled(FaArrowAltCircleLeft)`
    position: absolute;
    top: 60%;
    left: 50px;
    font-size: 3rem;
    color: black;
    z-index: 20;
    cursor: pointer;
    user-select: none;

    @media screen and (max-width: 1100px) {
        font-size: 2.8rem;
        left: 40px;
    }

    @media screen and (max-width: 700px) {
        font-size: 2.5rem;
        left: 30px;
    }
`

export const ArrowRight = styled(FaArrowAltCircleRight)`
    position: absolute;
    top: 60%;
    right: 50px;
    font-size: 3rem;
    color: black;
    z-index: 20;
    cursor: pointer;
    user-select: none;

    @media screen and (max-width: 1100px) {
        font-size: 2.8rem;
        right: 40px;
    }

    @media screen and (max-width: 700px) {
        font-size: 2.5rem;
        right: 30px;
    }
`
export const SliderTitle = styled.div`
    margin: 30px auto;
    text-align: center;

    @media screen and (max-width: 700px) {
        margin: 20px auto;
    }
`

export const SliderH1 = styled.h1`
    font-size: 2.7rem;
    margin-bottom: 10px;

    @media screen and (max-width: 1100px) {
        font-size: 2.2rem;
    }

    @media screen and (max-width: 700px) {
        font-size: 2rem;
    }

    @media screen and (max-width: 480px) {
        font-size: 1.8rem;
    }
`

export const SliderText = styled.p`
    font-size: 1.4rem;
    margin-bottom: 10px;

    @media screen and (max-width: 1100px) {
        font-size: 1.2rem;
    }

    @media screen and (max-width: 700px) {
        font-size: 1.1rem;
    }
`

export const SliderWrapper = styled.div`
    text-align: center;
`

export const Image = styled.img`
    width: 850px;
    height: 400px;
    border-radius: 10px;
    -o-object-fit: cover;
    object-fit: cover;
    margin-bottom: 50px;
    
    @media screen and (max-width: 1100px) {
        width: 800px;
        height: 375px;
        margin-bottom: 45px;
    }

    @media screen and (max-width: 900px) {
        width: 600px;
        height: 350px;
        margin-bottom: 40px;
    }

    @media screen and (max-width: 700px) {
        width: 400px;
        height: 325px;
        margin-bottom: 35px;
    }

    @media screen and (max-width: 480px) {
        width: 350px;
        height: 300px;
        margin-bottom: 30px;
    }

    @media screen and (max-width: 400px) {
        width: 250px;
        height: 280px;
        margin-bottom: 25px;
    }
`