import React from 'react'
import { 
    InfoContainer, 
    InfoH1, 
    // InfoH2, 
    InfoImg, 
    InfoP, 
    InfoLink, 
    InfoRow, 
    InfoWrapper2, 
    InfoContent2} from './InfoE'
import akt4 from '../../images/aktualnosci.jpg'

const InfoS = () => {
    return (
        <InfoContainer>
            <InfoH1>Aktualności</InfoH1>
            <hr style={{width: '65%', margin: '2px'}} /><hr style={{width: '25%', margin: '2px'}} /><hr style={{width: '65%', margin: '2px'}} />
            <InfoRow>
                <InfoWrapper2>
                    <InfoImg src={akt4}/>
                    <InfoContent2>
                        {/* <InfoH2>Aktualne oferty</InfoH2>
                        <hr style={{width: '50%'}}/> */}
                        <InfoP>Specjalne okazje wymagają specjalnej słodkiej oprawy. Zapoznaj się z naszymi sezonowymi ofertami.</InfoP>
                        <InfoLink to='/aktualnosci' target="_top">Czytaj więcej...</InfoLink>
                    </InfoContent2>
                </InfoWrapper2>
            </InfoRow>
        </InfoContainer>
    )
}

export default InfoS
