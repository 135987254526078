import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md'

export const GalleryContainer = styled.div`
    display: flex;
    justify-content: center;
    background: rgba(250,250,250,0.9);
`

export const Gc = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1100px;
`

export const GalleryH1 = styled.h1`
    font-size: 2.7rem;
    margin: 30px auto;

    @media screen and (max-width: 1100px) {
        font-size: 2.2rem;
        margin: 25px auto;
    }

    @media screen and (max-width: 700px) {
        font-size: 2rem;
        margin: 20px auto;
    }

    @media screen and (max-width: 480px) {
        font-size: 1.8rem;
        margin: 15px auto;
    }
`

export const GalleryWrap = styled.div`
    margin: 30px auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;

    @media screen and (max-width: 1100px){
        grid-template-columns: 1fr 1fr;
    }
    
    @media screen and (max-width: 770px) {
        grid-template-columns: 1fr;
    }
`

export const ImgWrap = styled.div`
    display: flex;
`

export const GalleryImg = styled.img`
    width: 220px;
    height: 220px;
    -o-object-fit: cover;
    object-fit: cover;
    box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
    border-radius: 5px;

    @media screen and (max-width: 1100px) {
        height: 250px;
        width: 250px;
    }

    @media screen and (max-width: 770px) {
        height: 300px;
        width: 300px;
    }
`

export const GalleryLink = styled(Link)`
    font-size: 1.4rem;
    margin-bottom: 2.5rem;
    color: gray;
    text-decoration: none;

    &:hover {
        color: black;
    }

    @media screen and (max-width: 1100px) {
        font-size: 1.2rem;
        margin-bottom: 2rem;
    }

    @media screen and (max-width: 700px) {
        font-size: 1.1rem;
        margin-bottom: 1.5rem;
    }
`

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 1.7rem;
    padding-top: 5px;

    @media screen and (max-width: 1100px) {
        font-size: 1.5rem;
    }

    @media screen and (max-width: 700px) {
        font-size: 1.3rem;
    }
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 1.7rem;
    padding-top: 5px;

    @media screen and (max-width: 1100px) {
        font-size: 1.5rem;
    }

    @media screen and (max-width: 700px) {
        font-size: 1.3rem;
    }
`