import styled from 'styled-components'

export const AktsContainer = styled.div`
    background: rgba(250,250,250,0.9);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: calc(100vh - 66.8px);
`

export const AktsH1 = styled.h1`
    margin: 120px auto 30px;
    text-align: center;
    font-size: 2.7rem;


    @media screen and (max-width: 1100px) {
        font-size: 2.2rem;
    }

    @media screen and (max-width: 700px) {
        font-size: 2rem;
        margin: 100px auto 30px;
    }

    @media screen and (max-width: 480px) {
        font-size: 1.8rem;
        margin: 120px auto 30px;
    }
`

export const GridContainer = styled.div`
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const AktsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 15px;

    @media screen and (max-width: 480px) {
        flex-direction: column;
    }
`

export const AktsH2 = styled.h2`
    font-size: 1.8rem;
    text-align: center;
    padding: 1rem 0;

    @media screen and (max-width: 1100px) {
        font-size: 1.6rem;
        padding: 0.8rem 0;
    }

    @media screen and (max-width: 700px) {
        font-size: 1.5rem;
        padding: 0.7rem 0;
    }

    @media screen and (max-width: 480px) {
        font-size: 1.4rem;
        padding: 0.6rem 0;
    }
`

export const AktsH3 = styled.h3`
    color: darkred;
    font-size: 1.8rem;
    padding: 1rem 0;

    @media screen and (max-width: 1100px) {
        font-size: 1.6rem;
        padding: 0.8rem 0;
    }

    @media screen and (max-width: 700px) {
        font-size: 1.5rem;
        padding: 0.7rem 0;
    }

    @media screen and (max-width: 480px) {
        font-size: 1.4rem;
        padding: 0.6rem 0;
    }
`

export const AktsContent = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 600px;
`

export const AktsP = styled.p`
    text-align: center;
    font-size: 1.4rem;
    margin: auto 10px 10px;

    @media screen and (max-width: 1100px) {
        font-size: 1.2rem;
    }

    @media screen and (max-width: 700px) {
        font-size: 1.1rem;
    }
`

export const AktsImg = styled.img`
    width: 300px;
    height: 400px;
    cursor: pointer;
    margin: 5px;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 1100px) {
        height: 360px;
        width: 270px;
    }

    @media screen and (max-width: 1100px) {
        height: 320px;
        width: 240px;
    }
`

export const Aktsspan = styled.span`
    text-align: center;
    font-size: 1rem;

    @media screen and (max-width: 1100px) {
        font-size: 0.9rem;
    }
`

export const AktsD = styled.p`
    color: darkred;
    font-size: 1.4rem;
    margin: auto 10px 10px;

    @media screen and (max-width: 1100px) {
        font-size: 1.2rem;
    }

    @media screen and (max-width: 700px) {
        font-size: 1.1rem;
    }
`

export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 999;
`

export const LargeImg = styled.img`
    display: block;
    max-width: 90%;
    max-height: 90%;
    margin: 60px auto;
    box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
    border: 3px solid white;
`