import React, {useState} from 'react'
// import { BrowserRouter as Router } from 'react-router-dom'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import HeroS from '../components/HeroS';
import InfoS from '../components/InfoS';
import OfferS from '../components/OfferS'
import Gallery from '../components/Gallery';
import Footer from '../components/Footer';
import FreeSpace from '../components/FreeSpace';
import ImageSlider from '../components/Witryna/ImageSlider';
import { SliderData } from '../components/Witryna/SliderData';
import AboutUs from '../components/AboutUs';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>
            <HeroS />
            <AboutUs />
            <FreeSpace />
            <ImageSlider items={SliderData}/>
            <FreeSpace />
            <InfoS />
            <FreeSpace />
            <OfferS />
            <FreeSpace />
            <Gallery />
            <Footer />
        </>
    )
}

export default Home
