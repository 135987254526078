import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const NavCom = styled.nav`
    background: transparent;
    height: 90px;
    margin-top: -90px;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 1100px) {
        height: 80px;
        margin-top: -80px;
        transition: 0.8s all ease;
    }

    @media screen and (max-width: 480px) {
        height: 60px;
        margin-top: -60px;
    }
`

export const Nav = styled.div`
    background: ${({ scrollNav }) => (scrollNav ? '#e1e2e5' : 'transparent')};
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: ${({ scrollNav }) => (scrollNav ? '3px 5px 7px rgba(0,0,0,0.1)' : '0')};
    transition: 0.5s all ease;
`

export const NavContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 90px;
    z-index: 1;
    width: 100%;
    max-width: 1100px;

    @media screen and (max-width: 1100px) {
        height: 80px;
        padding: 0 12px;
        transition: 0.8s all ease;
    }

    @media screen and (max-width: 480px) {
        height: 60px;
        padding: 0 6px;
        transition: 0.8s all ease;
    }
`

export const NavLogo = styled.img`
    width: 200px;
    height: 200px;
    justify-self: flex-start;
    display: ${({ scrollNav }) => (scrollNav ? 'none' : 'block')};
    transition: 1.2s all ease;
    position: absolute;
    left: 80;

    @media screen and (max-width: 700px) {
        width: 150px;
        height: 150px;
    }
`

export const NavLogoWrapper = styled(Link)`
    display: flex;
    position: relativ;
    text-decoration: none;
`

export const NavLogoText = styled.h1`
    display: ${({ scrollNav }) => (scrollNav ? 'flex' : 'none')};
    color: #6e93ae;
    font-weight: bold;
    align-items: center;
    font-size: 2.2rem;
    margin-left: 24px;
    transition: 0.8s all ease;

    @media screen and (max-width: 1100px) {
        font-size: 2rem;
        margin-left: 6px;
    }

    @media screen and (max-width: 480px) {
        font-size: 1.6rem;
    }
`

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 600px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.5rem;
        cursor: pointer;
        color: #6e93ae;

        &:hover {
            color: black;
        }
    }
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;

    @media screen and (max-width: 600px) {
        display: none;
    }
`

export const NavItem = styled.li`
    padding: 1px;
`

export const NavLinks = styled(Link)`
    color: #6e93ae;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 90px;
    cursor: pointer;
    transition: 0.8s all ease;

    &:hover {
        transition: all 0.2s ease-in-out;
        color: black;
        font-weight: bold;
        font-size: 1.6rem;
    }

    @media screen and (max-width: 1000px) {
        font-size: 1.4rem;
        height: 80px;
        transition: 0.8s all ease;
        padding: 0 0.7rem;

        &:hover {
            font-size: 1.5rem;
        }
    }

    @media screen and (max-width: 700px) {
        font-size: 1.3rem;
        height: 60px;
        transition: 0.8s all ease;

        &:hover {
            font-size: 1.4rem;
        }
    }
`