import styled from 'styled-components'

export const GContainer = styled.div`
    background: rgba(250,250,250,0.9);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: calc(100vh - 66.8px);
    padding-top: 120px;
`

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    justify-content: center;
    align-items: center;
    margin: 30px;
    grid-gap: 10px;
    grid-template-areas: 
        'img1 img1 img2 img3'
        'img4 img5 img6 img3'
        'img4 img7 img8 img8'
        'img9 img10 img11 img12'
        'img13 img13 img11 img14'
        'img15 img16 img17 img17'
        'img15 img18 img19 img20'
        'img21 img22 img22 img20'
        'img23 img24 img25 img26'
        'img23 img27 img27 img26'
        ;

    @media screen and (max-width: 960px) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 
            'img1 img2'
            'img3 img4'
            'img5 img6'
            'img7 img8'
            'img9 img10'
            'img11 img12'
            'img13 img14'
            'img15 img16'
            'img17 img18'
            'img19 img20'
            'img21 img22'
            'img23 img24'
            'img25 img26'
            'img27 img27'
            ;
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: 1fr;
        grid-template-areas: 
            'img1' 
            'img2'
            'img3'
            'img4'
            'img5'
            'img6'
            'img7'
            'img8'
            'img9' 
            'img10'
            'img11'
            'img12'
            'img13'
            'img14'
            'img15'
            'img16'
            'img17' 
            'img18'
            'img19'
            'img20'
            'img21'
            'img22'
            'img23'
            'img24'
            'img25'
            'img26'
            'img27'
            ;
    }
`

export const GH2 = styled.h2`
    text-align: center;
    margin: 10px;
    font-size: 1.4rem;
    padding: 1rem 0;

    @media screen and (max-width: 1100px) {
        font-size: 1.2rem;
        padding: 0.8rem 0;
    }

    @media screen and (max-width: 700px) {
        font-size: 1.2rem;
        padding: 0.7rem 0;
    }
`

export const GLink = styled.a`
    font-size: 1.4rem;
    margin-bottom: 2.5rem;
    color: gray;
    text-decoration: none;

    &:hover {
        color: black;
    }

    @media screen and (max-width: 1100px) {
        font-size: 1.2rem;
        margin-bottom: 2rem;
    }

    @media screen and (max-width: 700px) {
        font-size: 1.1rem;
        margin-bottom: 1.5rem;
    }
`

export const Img1 = styled.img`
    grid-area: img1;
    width: 410px;
    height: 350px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;
    
    @media screen and (max-width: 960px) {
        width: 200px;
    }

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img2 = styled.img`
    grid-area: img2;
    width: 200px;
    height: 350px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img3 = styled.img`
    grid-area: img3;
    width: 200px;
    height: 710px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;
    
    @media screen and (max-width: 960px) {
        height: 350px;
    }

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img4 = styled.img`
    grid-area: img4;
    width: 200px;
    height: 710px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 960px) {
        height: 350px;
    }

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img5 = styled.img`
    grid-area: img5;
    width: 200px;
    height: 350px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img6 = styled.img`
    grid-area: img6;
    width: 200px;
    height: 350px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img7 = styled.img`
    grid-area: img7;
    width: 200px;
    height: 350px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img8 = styled.img`
    grid-area: img8;
    width: 410px;
    height: 350px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 960px) {
        width: 200px;
    }

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img9 = styled.img`
    grid-area: img9;
    width: 200px;
    height: 350px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img10 = styled.img`
    grid-area: img10;
    width: 200px;
    height: 350px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img11 = styled.img`
    grid-area: img11;
    width: 200px;
    height: 710px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 960px) {
        height: 350px;
    }

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img12 = styled.img`
    grid-area: img12;
    width: 200px;
    height: 350px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img13 = styled.img`
    grid-area: img13;
    width: 410px;
    height: 350px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;
    
    @media screen and (max-width: 960px) {
        width: 200px;
    }

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img14 = styled.img`
    grid-area: img14;
    width: 200px;
    height: 350px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img15 = styled.img`
    grid-area: img15;
    width: 200px;
    height: 710px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;
    
    @media screen and (max-width: 960px) {
        height: 350px;
    }

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img16 = styled.img`
    grid-area: img16;
    width: 200px;
    height: 350px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img17 = styled.img`
    grid-area: img17;
    width: 410px;
    height: 350px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 960px) {
        width: 200px;
    }

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img18 = styled.img`
    grid-area: img18;
    width: 200px;
    height: 350px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img19 = styled.img`
    grid-area: img19;
    width: 200px;
    height: 350px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img20 = styled.img`
    grid-area: img20;
    width: 200px;
    height: 710px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 960px) {
        height: 350px;
    }

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img21 = styled.img`
    grid-area: img21;
    width: 200px;
    height: 350px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img22 = styled.img`
    grid-area: img22;
    width: 410px;
    height: 350px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 960px) {
        width: 200px;
    }

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img23 = styled.img`
    grid-area: img23;
    width: 200px;
    height: 710px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 960px) {
        height: 350px;
    }

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img24 = styled.img`
    grid-area: img24;
    width: 200px;
    height: 350px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img25 = styled.img`
    grid-area: img25;
    width: 200px;
    height: 350px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img26 = styled.img`
    grid-area: img26;
    width: 200px;
    height: 710px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 960px) {
        height: 350px;
    }

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const Img27 = styled.img`
    grid-area: img27;
    width: 410px;
    height: 350px;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 960px) {
        width: 200px;
    }

    @media screen and (max-width: 480px) {
        width: 250px;
    }
`