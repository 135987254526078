import React from 'react'
import { 
    OfferContainer, 
    OfferH1, 
    OfferH2, 
    OfferImg, 
    OfferLink, 
    OfferRow, 
    OfferP, 
    OfferContent2} from './OfferE'
import of3 from '../../images/oferty-gl.jpg'


const InfoS = () => {
    return (
        <OfferContainer>
            <OfferH1>Oferty</OfferH1>
            <hr style={{width: '65%', margin: '2px'}} /><hr style={{width: '25%', margin: '2px'}} /><hr style={{width: '65%', margin: '2px'}} />
            <OfferRow>
                <OfferContent2>
                    <OfferH2>Oferty okolicznościowe</OfferH2>
                    <hr style={{width: '50%'}}/>
                    <OfferP>W naszej ofercie znajdują się torty okolicznościowe, ciasta i słodkości. Zajmujemy się również kompleksową realizacją słodkich stołów na przyjęciach. Jeśli szukasz wyjątkowego tortu weselnego, pysznego ciasta dla gości lub słodkości na candy bar, dobrze trafiłeś! Zapraszamy do zapoznania się z naszą ofertą.</OfferP>
                    <OfferLink to='/oferta' target="_top">Czytaj więcej...</OfferLink>
                </OfferContent2>
                <OfferImg src={of3}/>
            </OfferRow>
        </OfferContainer>
    )
}

export default InfoS
