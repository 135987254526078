import React from 'react';
// import ReactDOM from 'react-dom';
import { Formik, Form, useField, Field } from 'formik';
import * as Yup from 'yup';
import './style.css'
import emailjs from 'emailjs-com';

const MyTextInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <>
            <label htmlFor={props.id || props.name}>{label}</label>
            <input className="text-input" {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </>
    );
};

const MyCheckbox = ({ children, ...props }) => {
   const [field, meta] = useField({ ...props, type: 'checkbox' });

    return (
        <div>
            <label className="checkbox">
                <input type="checkbox" {...field} {...props} />
                {children}
            </label>
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </div>
    );
};

const MySelect = ({ label, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <div>
            <label htmlFor={props.id || props.name}>{label}</label>
            <select {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </div>
    );
};

const Contact = () => {

   return (
    <>
        <Formik
            initialValues={{
                fullName: '',
                email: '',
                acceptedTerms: false, // added for our checkbox
                title: '', // added for our select
                date: '',
                message: '',
            }}
            validationSchema={Yup.object({
                fullName: Yup.string()
                    .required('To pole nie może być puste.'),
                email: Yup.string()
                    .email('Błędny adres email')
                    .required('To pole nie może być puste.'),
                acceptedTerms: Yup.boolean()
                    .required('Required')
                    .oneOf([true], 'Brak zgody.'),
                title: Yup.string()
                    .oneOf(
                    ['zamowienie', 'pytanie'],
                    'Invalid Job Type'
                    )
                    .required('W jakiej sprawie się z nami kontaktujesz?'),
                message: Yup.string()
                    .required('To pole nie może być puste.'),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                emailjs.send('gmail', 'template_398fpkp', {
                    name: values.fullName,
                    email: values.email,
                    message: values.message,
                    title: values.title,
                    date: values.date,
                }, 'user_UecjVtxpF25Qw9ybisDNr')
                    .then((result) => {
                        console.log(result.text);
                    }, (error) => {
                        console.log(error.text);
                    });
                resetForm({ values: ''});    

                // setTimeout(() => {
                //     alert(JSON.stringify(values, null, 2));
                //     setSubmitting(false);
                // }, 400);
                // console.log(values);
            }}
        >
            {({ values, errors, touched }) => (
            <div className='container'>   
                <div className='containerC'>
                    <Form className='formWrapper'>
                        <h1 className='formH1'>Formularz kontaktowy</h1>
                        <div className='elementWrapper'>
                            <p className='labelH2'></p>
                            <MyTextInput className='myTextInput'
                                
                                name="fullName"
                                type="text"
                                placeholder="Imię i nazwisko"
                            />
                        </div>
                        {/* {errors.name && touched.name ? (
                            <div className='error'>{errors.name}</div>
                        ) : null} */}
                        <div className='elementWrapper'>
                            <p className='labelH2'></p>
                            <MyTextInput className='myTextInput'
                            
                                name="email"
                                type="email"
                                placeholder="Adres email"
                            />
                        </div>
                        <div className='elementWrapper'>
                            <p className='labelH2'></p>
                            <MySelect name="title" className='myTextInput'>
                                <option value="">Temat kontaktu</option>
                                <option value="zamowienie">Zamówienie</option>
                                <option value="pytanie">Pytanie</option>
                            </MySelect>
                        </div>
                        {values.title === 'zamowienie' ? 
                        <div className='elementWrapper'>
                        <p className='labelH2'>Data realizacji</p>    
                            <MyTextInput className='myTextInput'
                                
                                name="date"
                                type="date"
                                placeholder="Data realizacji"
                            /></div> : false }
                        <div className='elementWrapper'>
                            <p className='labelH2'>Wiadomość</p>
                            <Field className='myTextInput'
                                component="textarea"
                                name="message"
                                type="message"
                                placeholder="Twoja wiadomość"
                                rows="9"
                                cols="70"
                            />
                        </div>
                        
                        <div className='elementWrapper'>
                            <MyCheckbox name="acceptedTerms" className='myCheckbox'>
                                <p className='acceptText'>Wyrażam zgodę na przetwarzanie danych osobowych zgodnie z ustawą o ochronie danych osobowych w związku z wysłaniem zapytania/zamówienia przez formularz kontaktowy. Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania/zamówienia. Zostałem poinformowany/-a, że przysługuje mi prawo dostępu do swoich danych, możliwości ich poprawiania, żądania zaprzestania ich przetwarzania. Administratorem danych osobowych jest KomuCiacho Michał Bąk, Bledzianów 68, 63-500 Ostrzeszów.</p>
                            </MyCheckbox>
                        </div>
                        <div className='elementWrapper'>    
                            <button type="submit" className='formBtn'>Wyślij</button>
                        </div>
                    </Form>
                </div>
            </div>
            )}
            
        </Formik>
    </>
    );
};

export default Contact