import React from 'react'
import { Backdrop, LargeImg} from './AktsE'

const Modal = ( {selectedImg, setSelectedImg} ) => {
    const handleClick = (e) => {
        if(e.target.classList.contains('close')){
            setSelectedImg(null);
        }
        
    }

    return (
        <Backdrop onClick={handleClick} className="close">
            <LargeImg src={selectedImg} alt='enlarged img'/>
        </Backdrop>
    )
}

export default Modal
