import React from 'react'
import { AboutContainer, 
    AboutH1, 
    // AboutH2, 
    AboutImg, 
    AboutRow, 
    AboutP, 
    AboutContent2} from './AboutUsE'
import of3 from '../../images/aboutus.jpg'


const InfoS = () => {
    return (
        <AboutContainer>
            <AboutH1>O nas</AboutH1>
            <hr style={{width: '65%', margin: '2px'}} /><hr style={{width: '25%', margin: '2px'}} /><hr style={{width: '65%', margin: '2px'}} />
            <AboutRow>
            
                
                <AboutContent2>
                    {/* <AboutH2>Gosia i Michał</AboutH2>
                    <hr style={{width: '50%'}}/> */}
                    <AboutP>Pracownia cukiernicza KomuCiacho została założona przed Gosię i Michała w lipcu 2019 roku.
                            Od początku zależało nam na tym, żeby nasze słodkości wyróżniały się ciekawą dekoracją, ale przede wszystkim skupiamy się na smaku. Często łączymy ze sobą na pozór nie pasujące do siebie nuty smakowe i tak powstają nasze niepowtarzalne komuciachowe słodkości! 
                            Stawiamy również na indywidualne podejście do klienta, a każde zamówienie realizujemy z pełnym zaangażowaniem.
                    </AboutP>
                </AboutContent2>
                <AboutImg src={of3}/>
            </AboutRow>
        </AboutContainer>
    )
}

export default InfoS
