import React from 'react'
import akt1 from '../../images/dzien-bd-oferta.jpg'
import akt2 from '../../images/degustacja-w.jpg'
import akt3 from '../../images/tlusty-czwartek.jpg'
import akt4 from '../../images/walentynki.jpg'
import { AktsContainer, AktsWrapper, AktsImg, Aktsspan, GridContainer, AktsH1, AktsH2, AktsContent, AktsP, AktsH3, AktsD } from './AktsE'

const Akts = ( {setSelectedImg} ) => {

    return (
        <AktsContainer>
            <AktsH1>Aktualności</AktsH1>
            <hr style={{width: '65%', margin: '2px'}} /><hr style={{width: '25%', margin: '2px'}} /><hr style={{width: '65%', margin: '2px'}} />
            <GridContainer>
                <AktsWrapper onClick={() => setSelectedImg(akt1)}>
                    <AktsContent>
                        <AktsImg src={akt1}/>
                        <Aktsspan>Kliknij obraz, aby powiększyć.</Aktsspan>
                    </AktsContent>
                    <AktsContent>    
                        <AktsH2>Dzień Babci i Dziadka</AktsH2>
                        <AktsP>Podaruj Swoim Dziadkom odrobinę słodyczy! Specjalnie z okazji ich święta przygotowaliśmy słodką ofertę. Zapraszamy do składania zamówień!</AktsP>
                    </AktsContent>
                </AktsWrapper>
                <hr style={{width: '65%', margin: '2px'}} /><hr style={{width: '25%', margin: '2px'}} /><hr style={{width: '65%', margin: '2px'}} />
                <AktsWrapper onClick={() => setSelectedImg(akt2)}>
                    <AktsContent>
                        <AktsH2>Degustacja weselna</AktsH2>
                        <AktsP>Więcej informacji wkrótce, oferta dostępna od 30.01.2021.</AktsP>
                    </AktsContent>
                    <AktsContent>
                        <AktsImg src={akt2}/>
                        {/* <Aktsspan>Kliknij obraz, aby powiększyć.</Aktsspan> */}
                    </AktsContent>
                </AktsWrapper>
                <hr style={{width: '65%', margin: '2px'}} /><hr style={{width: '25%', margin: '2px'}} /><hr style={{width: '65%', margin: '2px'}} />
                <AktsH3>Wkrótce</AktsH3>
                <hr style={{width: '65%', margin: '2px'}} /><hr style={{width: '25%', margin: '2px'}} /><hr style={{width: '65%', margin: '2px'}} />
                <AktsWrapper onClick={() => setSelectedImg(akt3)}>
                    <AktsContent>
                        <AktsImg src={akt3}/>
                        {/* <Aktsspan>Kliknij obraz, aby powiększyć.</Aktsspan> */}
                    </AktsContent>
                    <AktsContent>
                        <AktsH2>Tłusty czwartek</AktsH2>
                        <AktsP></AktsP>
                        <AktsD>Oferta dostępna 01.02.2021</AktsD>
                    </AktsContent>
                </AktsWrapper>
                <hr style={{width: '65%', margin: '2px'}} /><hr style={{width: '25%', margin: '2px'}} /><hr style={{width: '65%', margin: '2px'}} />
                <AktsWrapper onClick={() => setSelectedImg(akt4)}>    
                    <AktsContent>
                        <AktsH2>Walentynki</AktsH2>
                        <AktsP></AktsP>
                        <AktsD>Oferta dostępna 01.02.2021</AktsD>
                    </AktsContent>
                    <AktsContent>
                        <AktsImg src={akt4}/>
                        {/* <Aktsspan>Kliknij obraz, aby powiększyć.</Aktsspan> */}
                    </AktsContent>
                </AktsWrapper>
            </GridContainer>
        </AktsContainer>
    )
}

export default Akts
