import React from 'react'
import { HeroContainer, HeroContent} from './HeroE'

const HeroS = () => {
    return (
        <HeroContainer id='home'>
            <HeroContent>
                {/* Można dodać jakiś content */}
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroS
