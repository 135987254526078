import styled from 'styled-components'
import {Link} from 'react-router-dom'

export const FooterC = styled.footer`
    background: grey;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Wrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: 0 30px;
    width: 100%;

    @media screen and (max-width: 700px){
        margin: 0 20px;
    }

    @media screen and (max-width: 480px){
        margin: 0 10px;
    }
`

export const Logo = styled(Link)`
    text-decoration: none;
    color: #e1e2e5;
    cursor: pointer;
    font-size: 1.4rem;
    
    @media screen and (max-width: 1100px){
        font-size: 1.3rem;
    }

    @media screen and (max-width: 700px){
        font-size: 1.2rem;
    }

    @media screen and (max-width: 480px){
        font-size: 1rem;
    }
`

export const WRights = styled.p`
    color: #e1e2e5;
    font-size: 1.2rem;

    @media screen and (max-width: 1100px){
        font-size: 1rem;
    }

    @media screen and (max-width: 700px){
        font-size: 0.9rem;
    }

    @media screen and (max-width: 480px){
        font-size: 0.7rem;
    }
`

export const SocialIcons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
`

export const SocialIconLink = styled.a`
    color: #e1e2e5;
    cursor: pointer;
    text-decoration: none;
    margin-left: 10px;
`